.MuiDataGrid-row.in-conflict {
  animation: blink .4s step-end 2;
  background-color: #fbead9;
  border-bottom: 1px solid #e56d29;
  border-top: 1px solid #e56d29;
  transition: background-color .3s;
}

@keyframes blink {
  50% {
    background-color: transparent;
  }
}